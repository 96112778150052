import {Trans, useTranslation} from 'react-i18next';
import {Alert, AlertProps} from '@amzn/awsui-components-react';
import * as React from 'react';

export enum NotificationType {
  ERROR, INFO
}

const NOTIFICATION_ALERT_PROPS_MAP = new Map<NotificationType, AlertProps>([
  [NotificationType.ERROR, {statusIconAriaLabel: 'Error', type: 'error'}],
  [NotificationType.INFO, {statusIconAriaLabel: 'Info', type: 'info'}]
]);

interface NotificationProps {
  notificationType: NotificationType;
  message?: string;
  i18nKey?: string;
  header?: string;
  headerI18nKey?: string;
}

const Notification = (props: NotificationProps) => {
  const {t} = useTranslation(["translations"]);

  let message = props.i18nKey ? t(props.i18nKey) : props.message;
  const header = props.headerI18nKey ? t(props.headerI18nKey) : props.header;
  const alertProps = NOTIFICATION_ALERT_PROPS_MAP.get(props.notificationType);
  return (
    <Alert statusIconAriaLabel={alertProps?.statusIconAriaLabel}
           type={alertProps?.type}
           header={header}>
      <Trans>{message}</Trans>
    </Alert>
  )
}

export default Notification;