import {Cards, SpaceBetween} from '@amzn/awsui-components-react';
import ConfirmationMessage from './ConfirmationMessage';
import Button from '@amzn/awsui-components-react/polaris/button';
import * as React from 'react';
import {EventHandler} from 'react';
import ContentHeader from './ContentHeader';

export enum ResponsePageType {
  SUCCESS = 'success', ERROR = 'error', INFORMATION = ''
}

interface ResponsePageProps {
  message: string,
  orderedList?: string[],
  buttonName: string,
  buttonOnClick: EventHandler<any>;
  headerTitle: string;
  pageType?: ResponsePageType,
  linkText?: string,
  link?: string
}

const ResponsePage = (props: ResponsePageProps) => {
  const cards = [{cards: 1}];
  const items = [{
    type: "1A",
    size: "Small"
  }];
  return (
    <SpaceBetween size={"xxl"} alignItems={'center'}>
      <ContentHeader title={props.headerTitle}/>
      <Cards
        cardDefinition={{
          header: item => (
            <SpaceBetween size={"xxl"} alignItems={'center'} className={props.pageType}>
              <ConfirmationMessage
                message={props.message}
                orderedList={props.orderedList}
                linkText={props.linkText}
                link={props.link}/>
              <Button
                formAction="none"
                variant="primary"
                onClick={props.buttonOnClick}>
                {props.buttonName}
              </Button>
            </SpaceBetween>
          )
        }}
        cardsPerRow={cards}
        items={items}
      />
    </SpaceBetween>
  )
}

export default ResponsePage;