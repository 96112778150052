import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import { NavigateFunction, useOutletContext } from "react-router-dom";
import { SessionDetails } from "../../module/AuthAdminModule";
import {OPERATIONS, SERVICE_ADMIN, SITE_ADMIN} from '../../module/AuthAdminConfigModule';
import SelectPage from '../common/SelectPage';
import {capitalizeFirstLetter, EMPTY_STRING} from '../../util/helperUtil';
import {SpaceBetween} from "@amzn/awsui-components-react";
import ErrorNotification, {ErrorNotificationProps} from "../common/ErrorNotification";

const Service = () => {

    const navigate: NavigateFunction = useNavigate();
    let emptyErrorNotification = {} as ErrorNotificationProps
    const {t} = useTranslation(["translations"]);
    let servicesList: string[] = [];
    let serviceAdminMap: Map<string, string[]> = new Map();
    const [errorNotification, setErrorNotification] = React.useState(emptyErrorNotification);
    const sessionDetails: SessionDetails = useOutletContext();
    const userSessionInfo = sessionDetails.userSessionInfo;
    let currentAdminType: string = EMPTY_STRING;
    if (userSessionInfo.responseCode === 200) {
        // Creates ServicesList array, this array will be consumed by selectPage to display buttons for each service
        userSessionInfo.servicesAccessInfoList.forEach((accessInfo) => {
            if (!servicesList.includes(accessInfo.serviceName)) {
                servicesList.push(accessInfo.serviceName);
            }
            // To create and updates Service Admin Map.serviceAdminMap has mapping of service name with admin Type.
            // The Service Admin Map will be used by operations component to display various operations.
            if (serviceAdminMap.has(accessInfo.serviceName)) {
                const adminArray = serviceAdminMap.get(accessInfo.serviceName)!;
                adminArray.push(accessInfo.admintype);
            } else {
                serviceAdminMap.set(accessInfo.serviceName, [accessInfo.admintype]);
            }
        });
    } else {
        setErrorNotification({
            errorCode: "50001",
        });
    }

    const navigateToOperations = (serviceName: string) => {
        const adminArray = serviceAdminMap.get(serviceName) as string[];
        if (adminArray.includes(SITE_ADMIN)) {
            currentAdminType = SITE_ADMIN;
        } else {
            currentAdminType = SERVICE_ADMIN;
        }
        const operations = OPERATIONS.get(currentAdminType);
        navigate(`name_${serviceName}/operations`);
        localStorage.setItem("operations", JSON.stringify(operations));
        return;
    }

    const getButtonName = (serviceName: string) => {
        return capitalizeFirstLetter(serviceName);
    }

    return (
        <SpaceBetween size={"xs"} alignItems={'center'}>
            <SelectPage
                headerTitle={t("service_component_Header")}
                data={servicesList}
                buttonOnClick={navigateToOperations}
                buttonNameFn={getButtonName}/>
            {errorNotification.errorCode && <ErrorNotification {...errorNotification} />}
        </SpaceBetween>
    );
}
export default Service;
