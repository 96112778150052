import { currentApiDomain } from "../util/helperUtil";

export const INTERNAL_SERVER_ERROR_CODE = "50001";

export interface serviceAccess {
  admintype: string;
  serviceName: string;
}

export interface UserSessionInfo {
  responseCode: number;
  message: string;
  servicesAccessInfoList: serviceAccess[];
  userId: string;
  csrfToken: string;
}

export interface SearchAdminGroupResponse {
  groupId: string;
  responseCode: number;
  message: string;
}

export interface CreateSiteAdminResponse {
  responseCode: number;
  message: string;
}

export interface SessionDetails {
  userSessionInfo: UserSessionInfo;
  csrfToken: string;
}

export interface Exception {
  errorId: string;
  message: string;
}

export const RECOGNIZED_RESPONSE_STATUS = [200, 400, 401, 403, 500];

export const HOME_PAGE_URL: string = "/services";

// Gets invoked on load of UI. RootLayout component is the primary user of the response.
export const sessionInfoLoader = async (): Promise<SessionDetails> => {
  let response = await fetch(currentApiDomain + `/GetUserSessionInfo`, {
    method: "get",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "anti-csrftoken-a2z-request": "true",
    },
  });
  // On random usage of UI Uri's without appropriate authorization on a browser , UI redirects the user to /Authorize endpoint on API Domain.
  if (response.status === 401) {
    window.location.replace(currentApiDomain + "/Authorize");
  }
  const res = await response.json();
  return {
    userSessionInfo: res,
    csrfToken: response.headers.get("anti-csrftoken-a2z") || "",
  };
};
