import {Outlet, useLoaderData} from 'react-router-dom';
import Breadcrumbs from '../component/common/Breadcrumb';
import HeaderLayout from './HeaderLayout';
import {Box, SpaceBetween} from '@amzn/awsui-components-react';
import { SessionDetails } from "../module/AuthAdminModule";
import {useTranslation} from "react-i18next";

export default function RootLayout() {
    const {t} = useTranslation(["translations"]);
    const sessionDetails = useLoaderData() as SessionDetails;
    return (
        <div>
            <header>
                <SpaceBetween size={'xxl'}>
                    <HeaderLayout userId={sessionDetails.userSessionInfo.userId || t("UnAuthorizedUser")}/>
                    <Box margin={{left: 'xxl'}}>
                        <Breadcrumbs/>
                    </Box>
                </SpaceBetween>
            </header>
            <main>
                <Box margin={{top: "xxxl"}}>
                    <Outlet context={{...sessionDetails}}/>
                </Box>
            </main>
        </div>
    )
}