import ResponsePage, {
  ResponsePageType,
} from "../component/common/ResponsePage";
import * as React from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import HeaderLayout from "./HeaderLayout";
import { NavigateFunction } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { HOME_PAGE_URL } from "../module/AuthAdminModule";

export default function Logout() {
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation(["translations"]);
  return (
    <header>
      <SpaceBetween size={"xxl"}>
        <HeaderLayout />
        <ResponsePage
          message={t("logoutMessage")}
          buttonName={t("loginButtonName")}
          buttonOnClick={() => navigate(HOME_PAGE_URL)}
          headerTitle={t("logoutTitle")}
          pageType={ResponsePageType.INFORMATION}
        />
      </SpaceBetween>
    </header>
  );
}
