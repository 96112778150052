import {Box} from '@amzn/awsui-components-react';
import * as React from 'react';
import {Trans} from 'react-i18next';

interface ContentHeaderProps {
  title: string
}

const ContentHeader = (props: ContentHeaderProps) => {
  return (
    <Box variant="h1" fontSize="heading-xl" margin={{bottom: "xxl"}}>
      <Trans>{props.title}</Trans>
    </Box>
  );
}

export default ContentHeader;