import * as React from "react";
import { FormEvent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavigateFunction, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Form,
  FormField,
  Input,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import {
  currentApiDomain,
  getServiceNameFromLocation,
  isEmptyString,
} from "../../../util/helperUtil";
import {
  Exception,
  INTERNAL_SERVER_ERROR_CODE,
  RECOGNIZED_RESPONSE_STATUS,
  SearchAdminGroupResponse,
} from "../../../module/AuthAdminModule";
import ContentHeader from "../../common/ContentHeader";
import FormActions from "../../common/FormActions";
import ErrorNotification from "../../common/ErrorNotification";
import { EMPTY_STRING } from "../../../util/helperUtil";

const OPERATIONS_URL: string = "/services/name_maask/operations";
const SEARCH_ADMIN_GROUP_URL: string = `${currentApiDomain}/GetAdminGroupAndLocationMapping`;
const SiteIdForm = () => {
  const location = useLocation();
  const serviceName = getServiceNameFromLocation(location);
  const [errorCode, setErrorCode] = React.useState(EMPTY_STRING);
  const [siteId, setSiteId] = React.useState(EMPTY_STRING);
  const [inputValidationError, setInputValidationError] =
    React.useState(EMPTY_STRING);
  const [isLoading, setIsLoading] = React.useState(false);

  const onChangeFn = (value: string) => {
    setInputValidationError(EMPTY_STRING);
    setSiteId(value.toUpperCase());
  };

  const navigateToTeam = (groupId: string) => {
    navigate("#team", {
      state: {
        siteId: siteId,
        groupId: groupId,
      },
    });
  };

  const { t } = useTranslation(["translations"]);
  const navigate: NavigateFunction = useNavigate();

  const validateInputFields = () => {
    if (isEmptyString(siteId)) {
      setInputValidationError(t("error_message_required_field"));
      return false;
    }
    if (siteId.length > 7) {
      setInputValidationError(t("error_message_site_id_maxlength_7"));
      return false;
    }
    return true;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrorCode(EMPTY_STRING);
    if (!validateInputFields()) return;

    setIsLoading(true);
    const url = `${SEARCH_ADMIN_GROUP_URL}?locationId=${encodeURIComponent(
      siteId
    )}&serviceName=${encodeURIComponent(serviceName)}`;
    fetch(url, {
      method: "get",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 401) {
          // redirect to authenticate
          window.location.replace(currentApiDomain + "/Authorize");
          return res.json();
        }
        if (RECOGNIZED_RESPONSE_STATUS.includes(res.status)) {
          return res.json();
        }
        throw new Error("Unrecognized response status " + JSON.stringify(res));
      })
      .then((res: any) => {
        // Success:200
        if (res.hasOwnProperty("responseCode")) {
          res = res as SearchAdminGroupResponse;
          if (res.responseCode === 200) {
            if (res.groupId) {
              navigateToTeam(res.groupId.toString());
              return;
            }
            navigateToTeam(EMPTY_STRING);
            return;
          }
          setErrorCode(INTERNAL_SERVER_ERROR_CODE);
          return;
        }
        // Exception from backend
        if (res.hasOwnProperty("errorId")) {
          res = res as Exception;
          console.error("Unsuccessful API call", res.message, res);
          setErrorCode(res.errorId);
          return;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorCode(INTERNAL_SERVER_ERROR_CODE);
        console.error(err.message);
      });
  };

  const locationDescription = (
    <Trans>{t("locationFormFieldDescription")}</Trans>
  );

  const formPage = (
    <SpaceBetween size={"xxl"} alignItems={"center"}>
      {errorCode && <ErrorNotification errorCode={errorCode} />}
      <ContentHeader title={t("site_component_header")} />
      <form style={{}} onSubmit={(e) => handleSubmit(e)}>
        <Form
          className={"width-flex"}
          actions={
            <FormActions
              cancelButtonName={t("cancel_button_name")}
              submitButtonName={t("submit_button_name")}
              cancelOnClick={() => navigate(OPERATIONS_URL)}
              isLoading={isLoading}
            />
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField
              stretch
              label={t("site_id_form_field_label")}
              errorText={inputValidationError}
              description={locationDescription}
            >
              <Input
                value={siteId}
                onChange={(event) => onChangeFn(event.detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </form>
    </SpaceBetween>
  );

  return formPage;
};

export default SiteIdForm;
