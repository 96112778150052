import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationEnUs from './i18n/Locales/en-US/translations.json';

i18n.use(XHR)
    .use(LanguageDetector)
    .init({
        debug: false, // to disable debugging by i18n on console
        lng: "en-US", // default locale
        fallbackLng: "en-US", // fallback locale
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        resources: {
            "en-US": {
                translations: translationEnUs
            }
        },
        ns: ["translations"],
        defaultNS: "translations"
    });
export default i18n;