import * as React from 'react';
import {useTranslation} from "react-i18next";
import {NavigateFunction, useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {getServiceNameFromLocation} from '../../util/helperUtil';
import SelectPage from '../common/SelectPage';

const Operation = () => {
  const {t} = useTranslation(["translations"]);
  const location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const operationsString = localStorage.getItem("operations") || "";
  const operations: string[] = JSON.parse(operationsString);
  const serviceName = getServiceNameFromLocation(location);
  const navigateToSite = () => {
    navigate("create-site-admin");
  }

  const getButtonName = (operation: string) => {
    return t(operation);
  }

  return (
    <SelectPage
      headerTitle={t("operation_component_header", {
        // To update the service name with localization if needed in the future.
        serviceName: serviceName.toUpperCase()
      })}
      data={operations}
      buttonOnClick={navigateToSite}
      buttonNameFn={getButtonName}/>
  );
}
export default Operation;