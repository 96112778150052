import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { useTranslation } from "react-i18next";
import { TopNavigationProps } from "@amzn/awsui-components-react/uxdg";
import { HOME_PAGE_URL } from "../module/AuthAdminModule";

interface HeaderLayoutProps {
  userId?: string;
}

const HeaderLayout = (props: HeaderLayoutProps) => {
  const { t } = useTranslation(["translations"]);
  const signOutText = t("auth_admin_portal_sign_out_text");
  const utility: TopNavigationProps.Utility = {
    type: "menu-dropdown",
    text: props.userId,
    iconName: "user-profile",
    items: [{ id: "signout", text: signOutText }],
  };
  const utilities = props.userId ? [utility] : [];
  return (
    <TopNavigation
      id="h"
      identity={{
        href: HOME_PAGE_URL,
        title: t("auth_admin_portal_title"),
      }}
      utilities={utilities}
    />
  );
};
export default HeaderLayout;
