import * as React from "react";
import Notification, { NotificationType } from "./Notification";
import { useTranslation } from "react-i18next";

export interface ErrorNotificationProps {
  errorCode: string;
}

interface Error {
  message: string;
  header: string;
}

const getErrorMessage = (errorCode: string): Error => {
  if (errorCode) {
    if (errorCode === "40301") {
      return {
        message: "forbiddenErrorMessage",
        header: "forbiddenErrorCode",
      };
    }
    if (errorCode === "40101" || errorCode === "40102") {
      return {
        message: "unauthorizedErrorMessage",
        header: "unauthorizedErrorCode",
      };
    }
    if (
      errorCode === "40001" ||
      errorCode === "40002" ||
      errorCode === "40003" ||
      errorCode === "40004" ||
      errorCode === "40005"
    ) {
      return {
        message: "invalidInputErrorMessage",
        header: "invalidInputErrorCode",
      };
    }
    if (errorCode === "50001") {
      return {
        message: "internalServerErrorMessage1",
        header: "internalServerErrorCode",
      };
    }
  }
  return {
    message: "internalServerErrorMessage2",
    header: "internalServerErrorCode",
  };
};

const ErrorNotification = (props: ErrorNotificationProps) => {
  const { t } = useTranslation(["translations"]);

  if (!props.errorCode) {
    return <></>;
  }
  const error = getErrorMessage(props.errorCode);
  return (
    <Notification
      notificationType={NotificationType.ERROR}
      message={t(error.message)}
      header={t(error.header)}
    />
  );
};

export default ErrorNotification;
