import "./App.css";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Service from "./component/service/Service";
import RootLayout from "./layout/RootLayout";
import Operation from "./component/operation/Operation";
import CreateSiteAdmin from "./component/operation/create-site-admin/CreateSiteAdmin";
import { sessionInfoLoader } from "./module/AuthAdminModule";
import Logout from "./layout/LogoutLayout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Navigate to="services" />} />
      <Route element={<RootLayout />} loader={sessionInfoLoader}>
        {/*We decided to remove landing page, currently commenting the below line, we could end up using in future releases based on requirements*/}
        {/*<Route index element={<HomeComponent/>}/>*/}
        <Route path="services">
          <Route index element={<Service />} />
          <Route path="name_maask/operations">
            <Route index element={<Operation />} />
            <Route path="create-site-admin">
              <Route index element={<CreateSiteAdmin />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="logout" element={<Logout />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
