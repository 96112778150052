import ContentHeader from './ContentHeader';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import {getGridDefinition} from '../../util/helperUtil';
import Button from '@amzn/awsui-components-react/polaris/button';
import {SpaceBetween} from '@amzn/awsui-components-react';
import * as React from 'react';
import {EventHandler} from 'react';

interface SelectPageProps {
    headerTitle: string;
    data: any[];
    buttonOnClick: EventHandler<any>;
    buttonName?: string;
    buttonNameFn?: any;
}

const SelectPage = (props: SelectPageProps) => {
    return (
        <SpaceBetween size={"xxl"} alignItems={'center'}>
            <ContentHeader title={props.headerTitle}/>
            <Grid gridDefinition={getGridDefinition(props.data.length)}>
                {
                    props.data.map((d, index) => {
                        return <div key={index}>
                            <Button variant="primary" fullWidth onClick={() => props.buttonOnClick(d)}>
                                {props.buttonNameFn(d)}
                            </Button>
                        </div>
                    })
                }
            </Grid>
        </SpaceBetween>
    );
}

export default SelectPage;