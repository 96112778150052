import {Location} from '@remix-run/router';

export function capitalizeFirstLetter(parameter: string) {
  return parameter.charAt(0).toUpperCase() + parameter.slice(1);
}

export function isEmptyObj(obj: any) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}
export const EMPTY_STRING: string = "";
export const COLSPAN_12 = {colspan: 12};
// currentApiDomain to use across UI to make calls to backend API's.
export const currentApiDomain: string = window.origin.replace("https://", "https://api.");

export function getGridDefinition(length: number) {
  const elements: object[] = [];
  for (let element = 0; element < length; element++) {
    elements.push(COLSPAN_12);
  }
  return elements;
}

export function isEmptyString(value: string) {
  return value === undefined || value.trim().length === 0;
}

export function getServiceNameFromLocation(location: Location) {
  const crumbs = location.pathname.split("/");
  return crumbs[2].substring("name_".length).toUpperCase();
}

export function isEmptyArray(array: any[]) {
  return array === undefined || array.length === 0;
}
